import React from 'react';
import {
  CloseIcon,
  Line,
  Modal,
  SelectSearch,
  SpinnerIcon,
} from '@spa-cars/ui';
import {
  ApolloQueryResult,
  OperationVariables,
  useQuery,
} from '@apollo/client';
import {
  Order,
  Location,
  OrderProduct,
  OrderTotal,
  Product,
  Variant,
  Currency,
  User,
  Appointment,
  Driver,
} from '@spa-cars/models';
import ProductItem from '../../sale/product/ProductItem';
import Payment from '../payment/Payment';
import { getOrderTotal } from '../../../lib';
import NewAppointment from '../NewAppointment';
import { useNotify } from '../../../hooks';
import { GET_PRODUCTS, VALIDATE_NEXT_BLOCK } from '../../../graphql/queries';

interface FlushSaleModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  state: Partial<Order>;
  orderProducts: Array<Partial<OrderProduct>>;
  currencies: Array<Currency>;
  locationId: string;
  repairId: string;
  appointmentId: string;
  user: User;
  appointmentCurrentDate: Date;
  hasFlush: boolean;
  superFlushIsRequired: boolean;
  refetchAppointment: (variables?: Partial<OperationVariables>) => Promise<
    ApolloQueryResult<{
      appointment: Appointment;
    }>
  >;
  driver: Driver;
  isLoading: boolean;
}

const buildOptions = (options: OrderProduct[]) =>
  options?.map((option) => ({ ...option, name: option?.title }));
const buildOptionsVariant = (options: OrderProduct[]) =>
  options?.map((option) => ({
    ...option?.variant,
    _id: option?.variant?._id,
    name: [
      option?.variant?.option?.option1,
      option?.variant?.option?.option2,
      option?.variant?.option?.option3,
    ]
      .filter((x) => !!x)
      .join('/'),
  }));

function FlushSaleModal({
  isOpen,
  setOpen,
  state,
  orderProducts,
  currencies,
  locationId,
  repairId,
  user,
  isLoading,
  appointmentId,
  appointmentCurrentDate,
  hasFlush,
  superFlushIsRequired,
  refetchAppointment,
  driver,
}: FlushSaleModalProps) {
  const [orderProduct, setOrderProduct] =
    React.useState<Partial<OrderProduct>>(null);

  const [orderFlushService, setOrderFlushService] =
    React.useState<Partial<OrderProduct>>(null);

  const [orderTotal, setOrderTotal] = React.useState<Partial<OrderTotal>>(null);
  const [newAppointment, setNewAppointment] = React.useState<boolean>(hasFlush);
  const [paid, setPaid] = React.useState<boolean>(false);
  const [newAppointmentDate, setNewAppointmentDate] =
    React.useState<Date>(null); // date of the new appointment
  const [nextBlockAvailable, setNextBlockAvailable] =
    React.useState<boolean>(false);
  const notify = useNotify();

  const {
    loading: loadingFlushService,
    data: dataFlushService,
    error: errorFlushService,
  } = useQuery<{ getProducts: Product[] }>(GET_PRODUCTS, {
    variables: {
      data: {
        tags: ['aplicar-flush'],
        location: (state?.location as Location)?._id ?? '',
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data, loading } = useQuery<{
    validateNextBlock: { success: boolean };
  }>(VALIDATE_NEXT_BLOCK, {
    variables: {
      data: {
        date: appointmentCurrentDate,
        location: locationId,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleSelectProduct = (value: OrderProduct, name: string) => {
    setOrderProduct(value);
  };
  const handleSelectVariant = (value: Variant, name: string) => {
    setOrderProduct({
      ...orderProduct,
      variant: value,
      price: value?.price,
    });
  };

  React.useEffect(() => {
    if (orderProducts?.length > 0) {
      setOrderProduct(orderProducts[0]);
    }
  }, [orderProducts]);

  React.useEffect(() => {
    if (dataFlushService?.getProducts?.length > 0) {
      const flushService = dataFlushService?.getProducts[0];
      setOrderFlushService({
        title: flushService.title,
        product: flushService,
        variant:
          flushService?.variants?.length > 0 ? flushService?.variants[0] : {},
        quantity: 1,
        price:
          flushService?.variants?.length > 0
            ? flushService?.variants[0]?.price
            : 0,
      });
    }
  }, [dataFlushService]);

  React.useEffect(() => {
    if (orderProduct && orderFlushService) {
      setOrderTotal(
        getOrderTotal(
          [
            {
              product: orderProduct?.product as Product,
              variant: orderProduct?.variant as Variant,
              quantity: orderProduct?.quantity,
            },
            {
              product: orderFlushService?.product as Product,
              variant: orderFlushService?.variant as Variant,
              quantity: 1,
            },
          ],
          []
        )
      );
    }
  }, [orderProduct, orderFlushService]);

  React.useEffect(() => {
    if (data) {
      setNextBlockAvailable(data?.validateNextBlock?.success);
      if (!data?.validateNextBlock?.success && !hasFlush) {
        setNewAppointment(true);
      }
    }
  }, [data]);

  return (
    <Modal isOpen={isOpen} setOpen={setOpen} className=" md:ml-72  max-w-full">
      <div className="bg-gray-50 rounded-lg px-6 py-3">
        {/* Title */}
        <div className="flex justify-between items-center pb-3">
          <h2 className="text-xl text-primary-500 font-medium">
            Agregar flush al servicio
          </h2>
          <button
            type="button"
            aria-label="close"
            onClick={(e) => {
              e.preventDefault();

              setOpen(false);
            }}
          >
            <CloseIcon className="h-5 w-5 text-red-500" />
          </button>
        </div>
        <Line />
        {!loading && !isLoading && !loadingFlushService ? (
          <>
            <h2 className=" text-neutral-200  font-semibold mt-4">
              PAGO DEL FLUSH
            </h2>
            {orderProduct && orderFlushService ? (
              <>
                <div className="py-3 w-full">
                  <SelectSearch
                    onChangeSelect={handleSelectProduct}
                    options={
                      buildOptions((orderProducts as OrderProduct[]) ?? []) ??
                      []
                    }
                    defaultSelect={{
                      ...orderProduct,
                      name: orderProduct?.title,
                      _id: orderProduct?._id,
                    }}
                    disabled={orderProducts?.length === 0}
                    actionName=""
                    description="Seleccione un producto"
                    label="Producto"
                  />
                </div>
                <div className="py-3 w-full">
                  <SelectSearch
                    onChangeSelect={handleSelectVariant}
                    options={
                      buildOptionsVariant(
                        (orderProducts as OrderProduct[]) ?? []
                      ) ?? []
                    }
                    defaultSelect={{
                      ...orderProduct?.variant,
                      name: [
                        orderProduct?.variant?.option?.option1,
                        orderProduct?.variant?.option?.option2,
                        orderProduct?.variant?.option?.option3,
                      ]
                        .filter((x) => !!x)
                        .join('/'),
                      _id: orderProduct?.variant?._id,
                    }}
                    disabled={orderProduct?.product?.variants?.length === 0}
                    actionName=""
                    description="Seleccione una variante"
                    label="Variante"
                  />
                </div>
              </>
            ) : null}
            <div className="py-3">
              <h2 className=" text-neutral-100 text-sm font-semibold mb-[10px]">
                RESUMEN
              </h2>
              <Line />
              {state?.location ? (
                <>
                  <div className="flex justify-between mb-2 mt-[10px]">
                    <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                      Punto de servicio
                    </p>
                    <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                      {(state?.location as Location)?.name ?? 'N/A'}
                    </p>
                  </div>
                  <Line />
                </>
              ) : null}

              <ProductItem
                orderProduct={orderProduct as unknown as OrderProduct}
                modify={false}
                key={(orderProduct as unknown as OrderProduct)?._id}
                deleteOrderProduct={() => {}}
                confirmOrder
              />
              <ProductItem
                orderProduct={orderFlushService as unknown as OrderProduct}
                modify={false}
                key={(orderFlushService as unknown as OrderProduct)?._id}
                deleteOrderProduct={() => {}}
                confirmOrder
              />

              {orderTotal ? (
                <>
                  <div className="flex justify-between mb-2 mt-[10px]">
                    <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                      Subtotal
                    </p>
                    <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                      ${orderTotal?.subtotal?.toFixed(2) ?? 'N/A'}
                    </p>
                  </div>
                  <div className="flex justify-between mb-2">
                    <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                      Impuesto
                    </p>
                    <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                      ${orderTotal?.totalTax?.toFixed(2) ?? 'N/A'}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                      Total
                    </p>
                    <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                      {`$${orderTotal?.total?.toFixed(2) ?? 'N/A'}`}
                    </p>
                  </div>
                </>
              ) : null}
            </div>
            <Line />
            <div className="w-full flex items-center my-2">
              {!superFlushIsRequired ? (
                <input
                  type="checkbox"
                  checked={newAppointment ?? false}
                  onChange={(e) => {
                    // e.preventDefault()
                    if (
                      (newAppointment && !nextBlockAvailable) ||
                      (newAppointment && hasFlush)
                    ) {
                      if (hasFlush) {
                        notify(
                          'El servicio actual ya tiene flush, no se puede extender la cita',
                          'warning'
                        );
                      } else {
                        notify(
                          'El siguiente bloque horario no se encuentra disponible',
                          'warning'
                        );
                      }
                    } else {
                      setNewAppointment(!newAppointment);
                    }
                  }}
                  className="w-4 h-4  cursor-pointer text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
              ) : null}
              <label className="ml-2 text-neutral-300  text-lg" htmlFor="">
                {!superFlushIsRequired
                  ? '¿Reagendar servicio?'
                  : 'Reagendar Servicio'}
              </label>
            </div>
            {!superFlushIsRequired ? (
              <p className="text-sm mb-2 text-neutral-200">
                Si no se reagenda el servicio, se ocupará el siguiente bloque
                horario para realizar el flush una vez completado el pago.
              </p>
            ) : null}
            {newAppointment || superFlushIsRequired ? (
              <div className="py-3 w-full">
                <NewAppointment
                  newAppointmentDate={newAppointmentDate}
                  setNewAppointmentDate={setNewAppointmentDate}
                  location={locationId}
                />
              </div>
            ) : null}
            {!nextBlockAvailable &&
            !newAppointment &&
            !loadingFlushService ? null : (
              <div className="w-full flex justify-end gap-4">
                <Payment
                  setOpenModal={setOpen}
                  localCurrency={currencies?.find((c) => c?.localCoin)}
                  items={[
                    {
                      product: orderProduct?.product as Product,
                      variant: orderProduct?.variant as Variant,
                      quantity: orderProduct?.quantity ?? 1,
                    },
                    {
                      product: orderFlushService?.product as Product,
                      variant: orderFlushService?.variant as Variant,
                      quantity: 1,
                    },
                  ]}
                  refetchAppointment={refetchAppointment}
                  order={state}
                  discounts={[]}
                  locationId={locationId}
                  repairId={repairId}
                  paid={paid}
                  setPaid={setPaid}
                  appointmentId={appointmentId}
                  appointmentCurrentDate={appointmentCurrentDate}
                  newAppointment={newAppointment || superFlushIsRequired}
                  newAppointmentDate={newAppointmentDate}
                  driver={driver}
                  addFlush={!newAppointment || !superFlushIsRequired}
                />
              </div>
            )}
          </>
        ) : (
          <SpinnerIcon className="w-20 h-20 mx-auto text-gray-200 animate-spin dark:text-text-white fill-primary-300" />
        )}
      </div>
    </Modal>
  );
}

export default FlushSaleModal;
